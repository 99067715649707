<template>
    <div>
        <recess-table-flex 
            :table-options="setTableOptions"
            :pagination-options="setPaginationOptions"
            @getData="updateList"
        >
            <recess-table-flex-head :set-fixed-header="true">
                <recess-table-flex-row>
                    <recess-table-flex-head-item v-for="(th, index) in thead" :key="index" :class="`qa-users-list-header-${th.title}`">
                        <p class="m-0">{{ th.title }}</p>
                    </recess-table-flex-head-item>
                </recess-table-flex-row>
            </recess-table-flex-head>
            <recess-table-flex-body>
                <recess-table-flex-row
                    v-for="(user, index) in users"
                    :key="`user-key-${user.id}`"
                >
                    <recess-table-flex-cell :data-label="thead[0].title">
                        {{ user.firstName }} {{ user.lastName }}
                    </recess-table-flex-cell>

                    <recess-table-flex-cell
                        :class="`qa-user-list-email-${index}`"
                        :data-label="thead[1].title"
                    >
                        <p>{{ user.username }}</p>
                    </recess-table-flex-cell>

                    <recess-table-flex-cell
                        :class="`qa-user-list-roles-${index}`"
                        :data-label="thead[2].title"
                    >
                        <p v-for="(role, id) in user.roles" :key="id">{{ PROVIDER.UsersPage.UserRoleOptions[role] }}</p>
                    </recess-table-flex-cell>
                </recess-table-flex-row>
            </recess-table-flex-body>
        </recess-table-flex>
    </div>
</template>

<script>
import { API_CALL_URL_PATHS } from '@/../../shared/constants/constantsGeneral.json'
import { PROVIDER } from '@/constants/EdumsProviderConstants.json'
import { getItemById } from '@/../../shared/api/SharedClient'

export default {
    name: 'UserList',
    data() {
        return {
            PROVIDER,
            thead: [
                {title: PROVIDER.UsersPage.TableHeaders.Name, key: 'name'},
                {title: PROVIDER.UsersPage.TableHeaders.EmailAddress, key: 'email'},
                {title: PROVIDER.UsersPage.TableHeaders.Roles}
            ],
            users: [],
            setTableOptions: {
                setCellSizes: true 
            },
            setPaginationOptions: {
                showTopPagination: false,
                showBottomPagination: false
            }
        }
    },
    methods: {
        async getUsersByProvider() {
            const response = await getItemById(
                process.env.VUE_APP_AUTHORITY_URL,
                API_CALL_URL_PATHS.impersonate,
                localStorage.getItem('providerId'), 
                null,
                false
            )
            try {
                if (!response) return
                this.users = response
            } catch (error) {
                console.error('Something went wrong while retrieving the user list by provider id', error)
            }
        },
        updateList() {
            this.getUsersByProvider()
        }
    }
}
</script>